import $ from 'jquery';
import 'slick-carousel';
import Swiper from 'swiper/bundle';

export default () => {


  $(() => {
    
    // slide
    if (window.matchMedia('(max-width:768px)').matches) {
      // slide SP
      var addSwiper = $('.add-swiper');
      $.each(addSwiper, function(i, addSwiper){
        $(this).removeClass('multiple-items');
        $(this).wrapAll('<div class="swiper mySwiper"></div>');
        $(this).addClass('swiper-wrapper');
        $(this).find('.rankingBrands__item').wrap('<div class="swiper-slide"></div>');  // Top page ranking
        $(this).find('.related__item').wrap('<div class="swiper-slide"></div>'); // Detail page related
        $(this).find('.history__item').wrap('<div class="swiper-slide"></div>'); // Detail page history
        $(this).after('<div class="swiper-scrollbar"></div>');
        
        var swiperContainer = $('.mySwiper');
        $.each(swiperContainer, function(i, scrollBox){
          var mySwiper = new Swiper('.mySwiper', {
            spaceBetween: 9,
            slidesPerView: "auto",
            slidesOffsetAfter: 20,
            scrollbar: {
              el: ".swiper-scrollbar",
              hide: false,
              draggable: true
            },
          });
        });
      });
    } else {
      // slide PC
      // Top page ranking
      $('.multiple-items').slick({
        dots: false,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        variableWidth: true,
        prevArrow: '<button class="rankingBrands-arrow only-pc-s prev"></button>',
        nextArrow: '<button class="rankingBrands-arrow only-pc-s next"></button>'
      });
    }

    // Top page mainvisual slide PC
    $('.variable-width').slick({
      dots: true,
      infinite: true,
      speed: 300,
      autoplaySpeed: 3000,
      autoplay: true,
      slidesToShow: 1,
      centerMode: true,
      variableWidth: true,
      prevArrow: '<button class="mainvisual-arrow prev"></button>',
      nextArrow: '<button class="mainvisual-arrow next"></button>',
      responsive: [
        {
          breakpoint: 1025, 
          settings: {
            dots: false,
            variableWidth: false,
          }
        },
        {
          breakpoint: 426, 
          settings: {
            dots: false,
            variableWidth: false,
            centerMode: false
          }
        }
      ]
    });

    // Top page promotions slide SP
    $('.banner-top__list').slick({
      dots: false,
      slidesToShow: 1,
      autoplaySpeed: 3000,
      autoplay: true,
      centerMode: true,
      variableWidth: true,
      prevArrow: '<button class="banner-arrow prev"></button>',
      nextArrow: '<button class="banner-arrow next"></button>',
    });

    // ハンバーガーメニュー
    $('.l-header__menu').on('click',function() {
      $(this).toggleClass('active');
      if ($(this).hasClass('active')) {
        $('.navi').addClass('active');
      } else {
        $('.navi').removeClass('active');
      }
    });

    // Supplix ハンバーガーメニュー
    $('.l-headerSupplix__menu').on('click',function() {
      $(this).toggleClass('active');
      if ($(this).hasClass('active')) {
        $('.naviSupplix').addClass('active');
      } else {
        $('.naviSupplix').removeClass('active');
      }
    });

    // SP ハンバーガーメニュー
    $('.l-spHeader__menu').on('click',function() {
      $(this).toggleClass('active');
      if ($(this).hasClass('active')) {
        $('.spNavi').addClass('active');
      } else {
        $('.spNavi').removeClass('active');
      }
    });

    // 商品詳細ページ　カートインボタン
    var cartInBtn = $('#cartInBtn');
    cartInBtn.hide();
    $(window).scroll(function () {
      if ($(this).scrollTop() > 250) {
        cartInBtn.fadeIn();
      } else {
        cartInBtn.fadeOut();
      }
    });
    cartInBtn.click(function () {
      $(this).find('form').submit();
      return false;
    });

    // 商品一覧ページ　サイドバー
    $(".byCategory__sublist").hide();
    $(".byCategory__item-title.active").next().slideToggle();
    $(".byCategory__item-title").on("click", function() {
      $(this).next().slideToggle();
      $(this).toggleClass("active");
    });

    if (window.matchMedia('(max-width:1280px)').matches) {
      // プランド一覧ページ
      $(".brand__description").hide();
      $(".brand__term").on("click", function() {
        $(this).next().slideToggle();
        $(this).toggleClass("active");
      });
      // カテゴリ一覧ページ
      $(".category__description").hide();
      $(".category__term.active").next().slideToggle();
      $(".category__term").on("click", function() {
        $(this).next().slideToggle();
        $(this).toggleClass("active");
      });
    }

    //if (window.matchMedia('(max-width:1280px)').matches) {
      // アルファベットリスト中央寄せ
      var $grid = $('.alphabet__list'),
          emptyCells = [],
          i;
      for (i = 0; i < 20; i++) {
        emptyCells.push($('<li>', { class: 'alphabet__item is-empty' }));
      }
      $grid.append(emptyCells);
    //}
    
  });


  // プランド検索
  ({
    init: function(){
      var _this=this;
      $(function(){
        _this.quickSearch.init(_this);
      });
    },

    quickSearch:{
      master: null,
      cache: null,
      init: function(master: string){
        var _this=this;
        _this.cache=[];
        _this.master=master;
        $(function(){
          _this.setupCache();
        });
        $(window).on('load', function() {
          _this.createForm();
        });
      },

      // 入力フォーム生成
      createForm: function(){
        var _this = this;
        var timerID: any = null;
        var last: any ='';
        var $quickSearch = $('#quickSearch');

        if($quickSearch.is(':focus')===true){
          watchKeyword();
        }

        $quickSearch.focus(function(){
          watchKeyword();
        }).blur(function(){
          clearInterval(timerID);
          checkKeyword();
        });

        function watchKeyword(){
          checkKeyword();
          timerID= setTimeout(function() {
            watchKeyword();
          },100);
        }

        function checkKeyword(){
          var val = $quickSearch.val();
          if (val !== last) {
            last = val;
            _this.qSearch(val);
          }
        }
      },

      // キャッシュ生成
      setupCache: function() {
        var _this=this;

        $('#brandList').find('.brand__list').each(function(i){
          _this.cache[i] = [];
          var listTitle = $(this).find('.brand__term').text().replace(/\s/g,'');
          $(this).find('.brand__item').each(function(k){
            var arr: any = [];
            arr['heading'] = listTitle;
            arr['elm'] = $(this)[0].outerHTML;
            arr['keyword'] = $(this).find('.name').text();
            _this.cache[i][k]=arr;
          });
        });
      },

      // 絞り込み
      qSearch:function(str: string){
        var _this=this;

        // 単語一致
        var tmp='(\\s|^)' + str;

        var re = new RegExp(tmp,'i');
        var elmAll = '';

        $(_this.cache).each(function(i){
          var li='';
          var dt='';
          $(this).each(function(j){
            if(re.test(_this.cache[i][j].keyword)){
              li+=_this.cache[i][j].elm;
            }
            if(j==0){
              dt='<dt class="brand__term">'+_this.cache[i][j].heading+'</dt>';
            }
          });
          if(li!=''){
            elmAll+=('<dl class="brand__list" id="list'+_this.cache[i][0].heading+'">'+dt+'<dd class="brand__description"><ul class="brand__sublist">'+li+'</ul></dd>'+'</dl>');
          }
        });
        if(elmAll==''){ elmAll='<p id="noResult">沒有找到相關品牌。</p>' }
        $('#brandList').empty().html(elmAll);
      }
    }

  }).init();
}